import React, { Component } from 'react'
import axios from 'axios'
import Top from '../top';
var interval = ''
export default class Telemedicine extends Component {
    state={error: '', msg: '', loaderDiv: false, list:[], companyId: '', visitId: 0, companys: [], paid: 0, owing: 0, showSubmit: false, paymentTypes: [], paymentTypeIdError: '', paymentTypeId: '', consultants: [], consultantIdError: '', consultantId: '',mobileNumber: '', mobileNumberError: '', mobileType: '', instructions: '', pollId: 0, progress: true}

    componentDidMount() {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/telemedicine/getcompanys', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ companys: res.data });
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    
      }
       setLoader(loader) {
           this.setState({ loader: loader })
       }
    createMeeting()
       {       
         axios.get(localStorage.getItem('host') + '/telemedicine/createmeeting/'+this.state.visitId+'/'+sessionStorage.getItem('fullname').replace(" ","_")+'/'+this.state.companyId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                if(res.data > 0)
                   {
                   window.location.href = "/calltelemedicine?id="+res.data
                   }
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
                setTimeout(() => { this.setState({error: ''})  }, 5000);
            }
        )

       }
       getPrice(id)
         {
         for(let i in this.state.companys)
            {
             if(this.state.companys[i].id === Number(id))
                {
                 this.setState({owing: this.state.companys[i].fixedCost, serviceDetail: this.state.companys[i], paymentTypes: this.state.companys[i].paymentTypes, consultants: this.state.companys[i].consultants})
                }
            }
  
         }   
    doValidate()
        {
            setTimeout(() => {
                this.validate();
            }, 1000);   
        }    
        validate() {

            var error = false;
            if (this.state.consultantId === null) {
                this.setState({ consultantIdError: "Consultant cannot be empty" });
                error = true
            }
            else if (this.state.consultantId === "") {
                this.setState({ consultantIdError: "Consultant cannot be empty" });
                error = true
            }
            else if (this.state.consultantId === 0) {
                this.setState({ consultantIdError: "Consultant cannot be empty" });
                error = true
            }
            else {
                this.setState({ consultantIdError: "" })
            }
    
            if (this.state.paymentTypeId === null) {
                this.setState({ paymentTypeIdError: "Payment Type cannot be empty" })
                error = true
            }
            else if (this.state.paymentTypeId === "") {
                this.setState({ paymentTypeIdError: "Payment Type cannot be empty" })
                error = true
            }
            else {
                this.setState({ paymentTypeIdError: "" })
            }
    
            if (this.state.mobileNumber === null) {
                this.setState({ mobileNumberError: "Mobile Number cannot be empty" })
                error = true
            }
            else if (this.state.mobileNumber === "") {
                this.setState({ mobileNumberError: "Mobile Number cannot be empty" })
                error = true
            }
            else if(!(/^[0][7]\d{8}$/.test(this.state.mobileNumber)) )
                {
                 this.setState({ mobileNumberError: "Please numbers only should not start with zero like 07XXXXXXXX" })
                error = true
                }
            else {
                this.setState({ mobileNumberError: "" })
            }
            console.log(" error" + error)
           if (error === false) {
                this.setState({ error: "", showSubmit: true })
            }
        } 
    pay()
        {
            this.setLoader(true)
            var data = {id: this.state.serviceDetail.id,serviceId: this.state.serviceDetail.serviceId,patientId: sessionStorage.getItem('patientId'),consultantId: this.state.consultantId,currencyId: this.state.currencyId,paymentTypeId: this.state.paymentTypeId,userId: 0,name: this.state.serviceDetail.name,serviceName: this.state.serviceDetail.serviceName,tariffCode: this.state.serviceDetail.tariffCode,speciality: this.state.serviceDetail.speciality,serviceType: this.state.serviceDetail.serviceType,serviceClass: this.state.serviceDetail.serviceClass,fixedCost: this.state.serviceDetail.fixedCost,rate: this.state.rate, cashOnly: this.state.serviceDetail.cashOnly, allowEdit: this.state.serviceDetail.allowEdit,quantity: this.state.serviceDetail.quantity,margin: this.state.serviceDetail.margin, mobile: this.state.mobileNumber, mobileType: this.state.mobileType}
            axios.post(localStorage.getItem('host') + '/telemedicine/makepayment/'+sessionStorage.getItem('fullname'), data, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setLoader(false)
                    console.log(res.data)
                    if(res.data.id > 0)
                      {
                        this.setState({ instructions: res.data.instructions, pollId: res.data.id , progress: true});  
                        interval = setInterval(() => {
                            this.poll()
                        }, 1000 * 20)
                        
                      }
                     else
                       {
                        this.setState({ error: res.data.error });  
                        setTimeout(() => { this.setState({error: ''})  }, 5000);
                       } 
                      window.scrollTo(0, 0)
                }, err => {
                     this.setState({error: err+""})
                     window.scrollTo(0, 0)
                    this.setLoader(false)
                    setTimeout(() => { this.setState({error: ''})  }, 5000);
                }
            )   
        }  
    processPaymentType(id)
       {
        for(let i in this.state.paymentTypes)
             {
              if(Number(id) === this.state.paymentTypes[i].id)
                 {
                  this.setState({paymentTypeId: id, currencyId: this.state.paymentTypes[i].currencyId, rate: this.state.paymentTypes[i].rate, mobileType: this.state.paymentTypes[i].mobileType})
                 }
             }
       }   
    poll()
       {
        axios.get(localStorage.getItem('host') + '/telemedicine/poll/'+this.state.pollId, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                if(res.data.status === 'PAID')
                   {
                    clearInterval(interval)                        
                   this.setState({paid: this.state.owing, owing: 0, progress: false, visitId: res.data.visitId})
                   }
                   else if(res.data.status === 'CANCELLED' || res.data.status === 'ERROR' || res.data.status === 'UNDEFINED')
                   {
                    clearInterval(interval)
                    this.setState({error: res.data.message, progress: false, pollId: 0})
                    setTimeout(() => { this.setState({error: ''})  }, 5000);
                   } 
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
                setTimeout(() => { this.setState({error: ''})  }, 5000);
            }
        )
       }             
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        let progressLoader = ''   
        if(this.state.progress)
            {
             progressLoader = <div align="center" className="progress-loader"></div>   
            }   
        return ( <div className='d-flex' id='wrapper'>
        <div id='page-content-wrapper'>
                <Top />
                <br />
            <div className="container-fluid">
                <div className="user-form">
                <div  id="form">
                <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/home"><i class="bi bi-house"></i></a></li>
                        <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                        <li class="breadcrumb-item active" aria-current="page">Telemedicine</li>
                            </ol>
                            </nav>
                    {divMsg}
                    <ul className="list-group">
                                <li className="list-group-item"><select className="form-control" onChange={(e) => {this.setState({ companyId: e.target.value }); this.getPrice( e.target.value) }}  >
                                <option value="" >Choose Health Facility</option>
                                                {this.state.companys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select></li>
                                
                             <li className="list-group-item">    <br />
                             {this.state.companyId > 0 && this.state.pollId === 0 && (         
                    <div className='card'>       
                        <div className='card-body'>  
                            <p>Please note that a fee of USD $ {this.state.owing} is required to be paid before initiating a call with a consultant.</p>
                            <p>Please click the button below to pay the fee</p>
                            <div className='row'>
                                <div className='col'><div className="input-group has-validation">
                                                    <span className="input-group-text">Consultant</span>
                                                    <select className={this.state.consultantIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.consultantId} onChange={e => { this.setState({consultantId: e.target.value}); this.doValidate() }} >
                                                        <option value="">Choose Consultant</option>
                                                        {this.state.consultants.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.consultantIdError}</div>
                                                </div></div>
                            </div>
                            <div className='row'>
                                <div className='col'><div className="input-group has-validation">
                                                    <span className="input-group-text">Type</span>
                                                    <select className={this.state.paymentTypeIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.paymentTypeId} onChange={e => { this.processPaymentType(e.target.value);  this.doValidate() }} >
                                                        <option value="">Choose Type</option>
                                                        {this.state.paymentTypes.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.paymentTypeIdError}</div>
                                                </div></div>
                            </div>
                            <div className='row'>
                                <div className='col'><div className="input-group has-validation">
                                    <span className="input-group-text">Mobile Number (07XXXXXXXXX)</span>
                                    <input type="text" className={this.state.mobileNumberError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.mobileNumber} onChange={e => { this.setState({mobileNumber: e.target.value}); this.doValidate() }} />
                                <div className="invalid-feedback">{this.state.mobileNumberError}</div></div></div>
                            </div>
                            <div className='row'>
                                <div className='col'>{ this.state.showSubmit === true && <button  onClick={()=> this.pay()} className="btn btn-success" >Pay</button>} </div>
                            </div>   
                         </div>
                    </div>     
                    )}
                    {this.state.pollId > 0 && this.state.owing > 0 &&  <div className='card'>       
                        <div className='card-body'>  
                            <div className='row'>
                                <div className='col'>{this.state.instructions}</div>
                            </div>
                            <div className='row'>
                                <div className='col'><p>Checking for payment .....</p> {progressLoader}</div>
                            </div>                            
                            </div></div>}
                {this.state.paid > 0 &&         
                    <div className='row'>
                        <div className='col'><p>Please intiate video call by clicking the button below, a notification will be sent to the consultant.</p>
                        <button className='btn btn-success' onClick={()=> this.createMeeting()}><i class="bi bi-camera-video-fill"></i></button></div>
                    </div>
                    } 
                    </li>
                             </ul>
                    </div>
                        </div>
                       </div>
                        {loaderDiv}

                </div>
            </div>
        )

    }



}