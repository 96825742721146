import React, { Component } from 'react'
import axios from "axios";
function logout() {
    sessionStorage.clear();
    window.location.href = "/login"
}
export default class Top extends Component {

    state = { showLogin: true, showNav: Number(localStorage.getItem('width')) > 600 ? true : false, showDropDown: false, count: 0, notifications: [], showSubmit: false, username: '', usernameError: '', password: '', passwordError: '', error: '', ipAddress: '', country: '' }
    componentDidMount()
    {      
      document.getElementById("form").style.minHeight = localStorage.getItem("height")+"px";
      this.getNotifications()
      setInterval(this.getNotifications, 30000);
    }
    toggleNav() {
        this.setState({ showNav: !this.state.showNav })
    }
getNotifications()
    {
    var hasFocus = document.hasFocus()
    axios.get(localStorage.getItem('host') + '/auth/getnotifications/'+sessionStorage.getItem('refId')+"/"+hasFocus, {
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
         , validateStatus: () => true
          }).then(
            res => {
                console.log(res.status)
                console.log(res.data)
              if(res.status === 401 || res.status === "401")
                 {
                  logout()                    
                 }  
              else
                 {
                    if(res.data.stillLoggedIn === 0 || res.data.stillLoggedIn === "0")
                      {
                        logout() 
                      }
                 }   
            }, err => {
              console.log(".....")
            }
          )
    }
    render() {
        return (
            <div className="navbar navbar-expand-lg navbar-dark bg-primary">
                <table  style={{width: '100%'}}>
                    <tr>
                        <td className='longWidth'><a className="navbar-brand" href="/home"><i id="loginSvg" className="bi bi-heart-pulse-fill  link-main" ></i></a></td>
                        <td className='shortWidth'> <a className="nav-link" href="/mydetails"><i class="bi bi-person-circle link-main"></i></a></td>
                        <td className='shortWidth'> <a className="nav-link" href={false} onClick={() => logout()}><i class="bi bi-box-arrow-left link-main"></i></a></td>
                    </tr>
                </table>
            </div>
          
        )
    }
}