import React from 'react'
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./privateRoute"
import Index from './pages';
import Signup from './pages/signup';
import Login from './pages/login';
import Activate from './pages/activate';
import Forgot from './pages/forgot';
import Home from './pages/home';
import Mydetails from './pages/mydetails';
import UpdateDetails from './pages/update';
import Appointment from './pages/appointment/appointment';
import Prescription from './pages/prescriptions/prescription';
import PrescriptionView from './pages/prescriptions/viewprescription';
import Investigation from './pages/investigations/investigations';
import InvestigationView from './pages/investigations/viewinvestigation';
import MedicalCertificate from './pages/medicalcertificate/medicalcertificates';
import MedicalCertificateView from './pages/medicalcertificate/viewmedicalcertificate';
import TelemedicineCall from './pages/telemedicine/calltelemedicine';
import Telemedicine from './pages/telemedicine/telemedicine';

function App() {
  //  localStorage.setItem('host', 'https://test2.kimbah.co.zw');
  //localStorage.setItem('host', 'http://192.168.0.106:8085/ucarepatient'); 
  
    var url = process.env.REACT_APP_UCAREURL;
    console.log(window.location+"")
    var urlHost = ""
    if((window.location+"").includes(':3000'))
    {
    url =  'http://localhost:8084'
    urlHost =  'http://localhost:8081'
    }
    else if((window.location+"").includes('kimbah.co.zw'))
      {
        url = 'https://portal.gikko.net'
      }
    console.log("ENV ....."+window.location)

    console.log(window.screen.width)
    console.log(window.screen.height)
    localStorage.setItem('width', window.screen.width)
    localStorage.setItem('height', (Number(window.screen.height) * 0.8))
    localStorage.setItem('host',(url === undefined?"":url)+"/ucarepatient"); 
    localStorage.setItem('ucarehost',(urlHost === undefined?"":urlHost)+"/ucareis"); 
    return (
      <Router>
      <div className="App">
      <Routes>
              <Route  path='/' element={<Index/>} />
              <Route path="/login"   element={<Login/>} />
              <Route path="/signup"  element={<Signup/>} />
              <Route path="/activate"  element={<Activate/>} />
              <Route path="/forgot"  element={<Forgot/>} />
              <Route path="/home"  element={<PrivateRoute> <Home/></PrivateRoute>} />
              <Route path="/mydetails"  element={<PrivateRoute> <Mydetails/></PrivateRoute>} />
              <Route path="/update"  element={<PrivateRoute> <UpdateDetails/></PrivateRoute>} />
              <Route path="/appointment"  element={<PrivateRoute> <Appointment/></PrivateRoute>} />
              <Route path="/prescriptions"  element={<PrivateRoute> <Prescription/></PrivateRoute>} />
              <Route path="/viewprescription"  element={<PrivateRoute> <PrescriptionView/></PrivateRoute>} />
              <Route path="/investigations"  element={<PrivateRoute> <Investigation/></PrivateRoute>} />
              <Route path="/viewinvestigation"  element={<PrivateRoute> <InvestigationView/></PrivateRoute>} />
              <Route path="/medicalcertificates"  element={<PrivateRoute> <MedicalCertificate/></PrivateRoute>} />
              <Route path="/viewmedicalcertificate"  element={<PrivateRoute> <MedicalCertificateView/></PrivateRoute>} />
              <Route path="/telemedicine"  element={<PrivateRoute> <Telemedicine/></PrivateRoute>} />
              <Route path="/calltelemedicine"  element={<PrivateRoute> <TelemedicineCall/></PrivateRoute>} />
            </Routes>
      </div></Router>
    );
  }
export default App;
