import React, { Component } from 'react'
import axios from 'axios'
import Top from '../top';
export default class PrescriptionView extends Component {
    state={error: '', msg: '', loaderDiv: false,  data: "", companyId: '', speciality: '', source: ''}

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let id = Number(params.get('id'));
        let companyId = params.get('companyId')
        let speciality = params.get('speciality')
        let source = params.get('source')
        this.setState({id: id, companyId: companyId, speciality: speciality, source: source})
        this.setLoader(true)
        axios.get(localStorage.getItem('ucarehost') + '/pdf/getbase64image/'+id+'/PRESCRIPTION/'+companyId+'/'+speciality+'/'+source+'/'+id).then(
            res => {
                this.setLoader(false)
                this.setState({ data: res.data });
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    
      }
       setLoader(loader) {
           this.setState({ loader: loader })
       }
    downloadPdf() {
        var link = document.createElement('a');
        link.href = localStorage.getItem('ucarehost') + '/pdf/view/' + this.state.id+'/PRESCRIPTION/'+this.state.companyId+'/'+this.state.id+'/'+this.state.speciality+'/'+this.state.source;
        link.download = "Prescription.pdf";
        link.setAttribute('target', '_blank');
        link.click();
    }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return ( <div className='d-flex' id='wrapper'>
        <div id='page-content-wrapper'>
                <Top />
                <br />
            <div className="container-fluid">
                <div className="user-form">
                <div  id="form">
                <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/home"><i class="bi bi-house"></i></a></li>
                        <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                        <li class="breadcrumb-item"><a href="/prescriptions">Prescriptions</a></li>
                        <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                        <li class="breadcrumb-item active" aria-current="page">View Prescription</li>
                            </ol>
                            </nav>
                    {divMsg}                  
                    { this.state.data !== "" && (<div><img src={this.state.data} alt="File" style={{width: 100+'%'}} />
                   <ul className="list-group">
                                <li className="list-group-item"><div className="d-grid gap-2"><a href={false} onClick={() => this.downloadPdf()} className="btn btn-primary" >Download Pdf</a></div></li>
                            </ul></div>)}
                    </div>
                        </div>
                       </div>
                        {loaderDiv}

                </div>
            </div>
        )

    }



}