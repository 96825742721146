import React, { Component } from 'react'
import axios from 'axios'
import Top from './top';
export default class Home extends Component {
state={error: '', msg: '', loaderDiv: false, mobile: localStorage.getItem('mobile')?localStorage.getItem('mobile'):'', mobileError: '',  otpcode: '', otpcodeError: '', pincode: '', pincodeError: '', pincode1: '', pincode1Error: '', ipAddress: '', country: '', isp: '', showSubmit: false, show: false}

componentDidMount() {
    var msg = localStorage.getItem('msg') && localStorage.getItem('msg') !== ""?localStorage.getItem('msg'):""
    if(msg !== null && msg !== "")
        {
            localStorage.setItem('msg', '')
        }
 this.setState({msg: msg})
  

  }

doValidate() {
    setTimeout(() => {
        this.validate();
    }, 1000);
}

validate() {
       
    var error = false;
    if (this.state.mobile === null) {
        this.setState({ mobileError: "Mobile Number cannot be empty" })
        error = true
    }
    else if (this.state.mobile === "") {
        this.setState({ mobileError: "Mobile Number cannot be empty" })
        error = true
    }
    else if(!(/^[7]\d{8}$/.test(this.state.mobile)))
        {
        this.setState({ mobileError: "Please numbers only should not start with zero like 772747211"})
        error = true
        }
    else {
        this.setState({ mobileError: "" })
    }
    if (this.state.otpcode === null) {
        this.setState({ otpcodeError: "OTP code cannot be empty" })
        error = true
    }
    else if (this.state.otpcode === "") {
        this.setState({ otpcodeError: "OTP code cannot be empty" })
        error = true
    }
    else if(!(/^(\d{6})$/.test(this.state.otpcode)))
        {
        this.setState({ otpcodeError: "OTP code should be 6 digits only"})
        error = true
        }
    else {
        this.setState({ otpcodeError: "" })
    }
    if (this.state.pincode === null) {
        this.setState({ pincodeError: "Pincode cannot be empty" })
        error = true
    }
    else if (this.state.pincode === "") {
        this.setState({ pincodeError: "Pincode cannot be empty" })
        error = true
    }
    else if(!(/^(\d{4})$/.test(this.state.pincode)))
        {
        this.setState({ pincodeError: "Pincode should be 4 digits only"})
        error = true
        }
    else {
        this.setState({ pincodeError: "" })
    }

    if (this.state.pincode1 === null) {
        this.setState({ pincode1Error: "Pincode cannot be empty" })
        error = true
    }
    else if (this.state.pincode1 === "") {
        this.setState({ pincode1Error: "Pincode cannot be empty" })
        error = true
    }
    else if(!(/^(\d{4})$/.test(this.state.pincode1)))
        {
        this.setState({ pincode1Error: "Pincode should be 4 digits only"})
        error = true
        }
    else if(this.state.pincode1 !== this.state.pincode){
        this.setState({ pincode1Error: "Pincodes do not match" , pincodeError: "Pincodes do not match" })
    }
    else {
        this.setState({ pincode1Error: "" })
    }
    if(error === true)
           {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false}) 
           }
        else
           {
            this.setState({error: "", showSubmit: true}) 
           }   
}
reset() {
    this.setState({ error: '' });
    this.setLoader(true)
    axios.get(localStorage.getItem('host') + '/login/reset/'+this.state.mobile+'/'+this.state.otpcode+'/'+this.state.pincode).then(
        res => {
            this.setLoader(false)
            if (res.data.includes('Error')) {
                this.setState({ error: res.data });
              }
              else {               
                localStorage.setItem('msg', res.data)
                window.location = "/login"                
              }
        }, err => { console.log(err+"")
             this.setState({error: err+""})
            this.setLoader(false)
        }
    )
}
getOTP() {
    this.setState({ error: '' });
    this.setLoader(true)
    axios.get(localStorage.getItem('host') + '/login/resendotp/'+this.state.mobile).then(
        res => {
            this.setLoader(false)
            if (res.data.includes('Error')) {
                this.setState({ error: res.data });
              }
              else {
               
                this.setState({msg: "Successfully sent OTP code to your phone"})
               
              }
        }, err => {
             this.setState({error: err+""})
            this.setLoader(false)
        }
    )
}
setLoader(loader)
{
 this.setState({loader: loader})
}
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className='d-flex' id='wrapper'>
                <div id='page-content-wrapper'>
                        <Top />
                        <br />
                    <div className="container-fluid">
                    <div className="user-form">
                    <div  id="form">
                        <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="/home"><i class="bi bi-house"></i></a></li>
                                    </ol>
                                    </nav>
                            {divMsg}
                            <ul className="list-group">
                                <li className="list-group-item"><div className="d-grid gap-2"><a href="/appointment" className="btn btn-primary" style={{marginLeft: 0, marginRight: 0}} >Appointments / Review</a></div></li>
                                <li className="list-group-item"><div className="d-grid gap-2"><a href="/prescriptions" className="btn btn-primary" style={{marginLeft: 0, marginRight: 0}} >My Prescriptions</a></div></li>
                                <li className="list-group-item"><div className="d-grid gap-2"><a href="/investigations" className="btn btn-primary" style={{marginLeft: 0, marginRight: 0}} >My Lab  / Radiology Investigations</a></div></li>
                                <li className="list-group-item"><div className="d-grid gap-2"><a href="/medicalcertificates" className="btn btn-primary" style={{marginLeft: 0, marginRight: 0}} >Medical Certificates</a></div></li>
                               <li className="list-group-item"><div className="d-grid gap-2"><a href="/telemedicine" className="btn btn-primary" style={{marginLeft: 0, marginRight: 0}} >Telemedicine</a></div></li> 
                            </ul>
                             </div>
                        </div>
                       </div>
                        {loaderDiv}

                </div>
            </div>
        )

    }



}