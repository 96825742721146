import React, { Component } from 'react'
import axios from 'axios'
import Top from '../top';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const now = new Date();
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Appointment extends Component {
state={error: '', msg: '', loaderDiv: false, clusterCompanys:[], consultants: [], startDate: now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()), titles:[], timeTables: [], dates: [], mobile: localStorage.getItem('mobile')?localStorage.getItem('mobile'):'', appointmentTime: '',  appointmentTimeId: 0, appointmentDate: '', pincode: '', pincodeError: '', pincode1: '', pincode1Error: '', ipAddress: '', country: '', isp: '', showSubmit: false, consultantId: 0}

componentDidMount() {
    axios.get(localStorage.getItem('host') + '/auth/getcompanys', {
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({ clusterCompanys: res.data });
        }, err => {
            this.setState({ error: err + "" })
            this.setLoader(false)
        }
    )

  }

  doList() {
    setTimeout(() => {
        this.getTimeTableList();
    }, 1000);
}

getTimeTableList() {
    this.setState({ list: [], msg: '' });
    if(this.state.companyId > 0 && this.state.startDate !== "")
    {
        this.setLoader(true)
    axios.get(localStorage.getItem('host') + '/patient/getcalendar/'+sessionStorage.getItem('patientId')+'/'+this.state.companyId+'/'+this.state.consultantId+'/'+this.state.startDate,  {
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({ titles: res.data.titles, dates: res.data.dates, timeTables: res.data.timeTables });
            this.setLoader(false)
        }, err => {
            this.setState({ error: err + "" })
            this.setLoader(false)
        }
    )
    }
}
setLoader(loader)
{
 this.setState({loader: loader})
}

openAppointmentByEntry(appointmentTime, appointmentTimeId, appointmentDate)
{
  this.setState({appointmentTime: appointmentTime,  appointmentTimeId: appointmentTimeId, appointmentDate: appointmentDate})
    document.getElementById('appointmentAction').style = 'display:block'
}
close()
  {
    document.getElementById('appointmentAction').style = 'display:none'
  }
  save()
  {
    axios.get(localStorage.getItem('host') + '/patient/saveappointment/'+this.state.appointmentTimeId+'/'+this.state.appointmentDate+'/'+sessionStorage.getItem('patientId')+'/'+this.state.companyId,  {
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then(
        res => {
            if(res.data.includes("Error"))
            {
                this.setState({ error: res.data });
                this.close()
                this.setLoader(false)
            }
            else
            {
                this.close()
                this.setState({ msg: res.data });
                this.getTimeTableList()
            }
        }, err => {
            this.setState({ error: err + "" })
            this.setLoader(false)
        }
    )
  }
  processDate(date)
    {
      const now = new Date(date);
      const todayY = now.getFullYear();
      const todayM = checkZero(now.getMonth() + 1);
      const todayD = checkZero(now.getDate());
      var date1 = todayY + '-' + todayM + '-' + todayD;
      console.log(date1)
     return date1;
      

    }
    getConsultants(index)
    {
     this.setState({companyId: this.state.clusterCompanys[index].id, consultants: this.state.clusterCompanys[index].consultants})   
     this.doList()
    }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className='d-flex' id='wrapper'>
                <div id='page-content-wrapper'>
                        <Top />
                        <br />
                    <div className="container-fluid">
                        <div className="user-form">
                        <div  id="form">
                        <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="/home"><i class="bi bi-house"></i></a></li>
                                <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                                <li class="breadcrumb-item active" aria-current="page">Appointment</li>
                                    </ol>
                                    </nav>
                            {divMsg}
                            <ul className="list-group">
                                <li className="list-group-item"><div className="input-group"> <span className="input-group-text">Date (YYYY-MM-DD)</span><DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) }); this.doList()} } dateFormat="yyyy-MM-dd" /></div></li>
                                <li className="list-group-item"><div className="input-group"> <span className="input-group-text">Health Facility</span><select className="form-control" onChange={(e) => this.getConsultants( e.target.value) }  >
                                <option value="" >Choose Health Facility</option>
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={index} >{item.name}</option>
                                                ))}
                                            </select></div></li>
                                  <li className="list-group-item"><div className="input-group"> <span className="input-group-text">Doctor</span><select className="form-control" onChange={(e) => { this.setState( { consultantId: e.target.value}); this.doList()} }  >
                                <option value="" >Choose Doctor</option>
                                                {this.state.consultants.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select></div></li>
                             </ul>

                 <table className="table table-striped table-bordered" >
                    <thead>
                        <tr>
                            <th>Time</th>
                            {this.state.titles.map((item, index) => (
                            <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                {this.state.timeTables.map((item, index) => (
                        <tr key={index}>
                            <th>{item.time}</th>
                           {item.entry1 === ""?<td className="link" onClick={()=> this.openAppointmentByEntry(item.time, item.timeId, this.state.dates[0])}></td> :<td className=" bg-info"></td>}
                           {item.entry2 === ""?<td className="link" onClick={()=> this.openAppointmentByEntry(item.time, item.timeId, this.state.dates[1])}></td> : <td className=" bg-info"></td>}
                           {item.entry3 === ""?<td className="link" onClick={()=> this.openAppointmentByEntry(item.time, item.timeId, this.state.dates[2])}></td> : <td className=" bg-info"></td>}
                           {item.entry4 === ""?<td className="link" onClick={()=> this.openAppointmentByEntry(item.time, item.timeId, this.state.dates[3])}></td> : <td className=" bg-info"></td>}
                           {item.entry5 === ""?<td className="link" onClick={()=> this.openAppointmentByEntry(item.time, item.timeId, this.state.dates[4])}></td> : <td className=" bg-info"></td>}
                           {item.entry6 === ""?<td className="link" onClick={()=> this.openAppointmentByEntry(item.time, item.timeId, this.state.dates[5])}></td> : <td className=" bg-info"></td>}
                           {item.entry7 === ""?<td className="link" onClick={()=> this.openAppointmentByEntry(item.time, item.timeId, this.state.dates[6])}></td> : <td className=" bg-info"></td>}
                        </tr>
                         ))}
                    </tbody>
                 </table>
                 <div id="appointmentAction" className="page-popup page-popup-overlay"   >
                    <div className="card" id="card" >
            <div className="card-header  bg-info">Appointment Action</div>
            <div className="card-body" >
            <div className="row">
                                                    <div className="col"><span className="fw-bold">Date:</span> {this.state.appointmentDate}</div>
                                                    <div className="col"><span className="fw-bold">Time:</span> {this.state.appointmentTime}</div>
                                                </div>
                             <div className="row">
                                <div className="col"><div className="d-grid gap-2"><button  onClick={() => this.save()}  className="btn btn-primary" >Book</button></div></div>
                                <div className="col"><div className="d-grid gap-2"><button  onClick={() => this.close()}  className="btn btn-primary" >Close</button></div></div>
                                </div>
                             <div className="row">
                                </div>

            </div>
            </div></div>
                             </div>
                        </div>
                       </div>
                        {loaderDiv}

                </div>
            </div>
        )

    }



}