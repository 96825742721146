import React, { Component } from 'react'
import axios from 'axios'
import Top from '../top';
export default class Investigation extends Component {
    state={error: '', msg: '', loaderDiv: false, list:[]}

    componentDidMount() {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patient/getinvestigationvisitlist/'+sessionStorage.getItem('patientId'), {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    
      }
      setLoader(loader) {
          this.setState({ loader: loader })
      }

      openInvestigation(id, companyId, speciality, type, visitId, source)
      {
       window.location.href = "/viewinvestigation?id="+id+'&companyId='+companyId+"&type="+type+"&visitId="+visitId+"&speciality="+speciality+"&source="+source

      }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return ( <div className='d-flex' id='wrapper'>
        <div id='page-content-wrapper'>
                <Top />
                <br />
            <div className="container-fluid">
                <div className="user-form">
                <div  id="form">
                <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/home"><i class="bi bi-house"></i></a></li>
                        <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                        <li class="breadcrumb-item active" aria-current="page">Investigations</li>
                            </ol>
                            </nav>
                    {divMsg}
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Doctor</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.list.map((item, index) => (
                            <tr key={index} >
                                <td>{item.name}</td>
                                <td>{item.treatmentDateTime}</td>
                                <td>{item.doctor}</td>
                                <td><span className="link" onClick={(e)=> { this.openInvestigation(item.id, item.companyId, item.speciality, item.type, item.visitId, 'GP') }}><i class="bi bi-folder2-open"></i></span></td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                        </div>
                       </div>
                        {loaderDiv}

                </div>
            </div>
        )

    }



}