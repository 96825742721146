import React, { Component } from 'react'
import axios from 'axios'
import Top from '../top';
import ZoomMtgEmbedded from "@zoomus/websdk/embedded"
const KJUR = require('jsrsasign')
export default class TelemedicineCall extends Component {
    state={error: '', msg: '', loaderDiv: false, list:[], companyId: '', companys: []}
    
    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let id = Number(params.get('id'));
        this.joinMeeting(id);
      }
       setLoader(loader) {
           this.setState({ loader: loader })
       }
    joinMeeting(id)
       {
         axios.get(localStorage.getItem('host') + '/telemedicine/getmeeting/'+id, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setLoader(false)
                var token = this.generateSignature(res.data.sdkKey, res.data.sdkSecret, res.data.joinId, 1)
                const client = ZoomMtgEmbedded.createClient()
                let meetingSDKElement = document.getElementById('meetingSDKElement')
                client.init({ zoomAppRoot: meetingSDKElement, language: 'en-US' ,debug: true})
              
                client.join({
                    sdkKey: res.data.sdkKey,
                    signature: token,
                    meetingNumber: res.data.joinId,
                    password: res.data.password,
                    userName: sessionStorage.getItem('fullname')
                })
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )

       }
       generateSignature(key, secret, meetingNumber, role) {

        const iat = Math.round(new Date().getTime() / 1000) 
        const exp = iat + (60 * 60 * 2)
        const oHeader = { alg: 'HS256', typ: 'JWT' }
      
        const oPayload = {
          sdkKey: key,
          mn: meetingNumber,
          role: role,
          iat: iat,
          exp: exp,
          tokenExp: exp
        }
      
        const sHeader = JSON.stringify(oHeader)
        const sPayload = JSON.stringify(oPayload)
        console.log(sPayload)
        const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, secret)
        return sdkJWT
      }
    render() {
        let divMsg = ''
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return ( <div className='d-flex' id='wrapper'>
        <div id='page-content-wrapper'>
                <Top />
                <br />
            <div className="container-fluid">
                <div className="user-form">
                <div  id="form">
                <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/home"><i class="bi bi-house"></i></a></li>
                        <li class="breadcrumb-item"><i class="bi bi-chevron-right"></i></li>
                        <li class="breadcrumb-item active" aria-current="page">Tele-Medicine</li>
                            </ol>
                            </nav>
                    {divMsg}
                    <div id="meetingSDKElement">
</div>
                    </div>
                        </div>
                       </div>
                        {loaderDiv}

                </div>
            </div>
        )

    }



}